import React, { useEffect } from "react";
import { isIOS, isAndroid, isDesktop } from 'react-device-detect'
import { GooglePlayButton, AppStoreButton } from "react-mobile-app-button";
import logo from "./logo.svg";
import "./App.css";


function App() {
  const appScheme = `challapp:/${window.location.pathname}`;

  useEffect(() => {
    setTimeout(() => {
      window.location.href = appScheme;
    }, 300)
  }, [appScheme])

  const redirect = () => {
    if (isIOS) {
      // Temporary link
      window.location.href = "https://apps.apple.com/us/app/chall/id6447187230";
      return;
    }
    if (isAndroid) {
      // Temporary link
      window.location.href = "https://play.google.com/store/apps/details?id=com.chall";
      return;
    }
    if (isDesktop) return;
  };

  useEffect(() => {
    setTimeout(() => {
      redirect()
    }, 1000);
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>Chall</p>
        <div className="open-link" onClick={() => window.location.href = appScheme}>Open Link</div>
        <GooglePlayButton
          url={'https://play.google.com/store/apps/details?id=com.chall'}
          theme={"dark"}
          width={276}
          height={40}
          className={"install-app"}
        />
        <AppStoreButton
          url={'https://apps.apple.com/us/app/chall/id6447187230'}
          theme={"dark"}
          width={276}
          height={40}
          className={"install-app"}

        />
      </header>
    </div>
  );
}

export default App;
